import { LABEL } from "./Uploader";

const DELIMITER = ':::' // tuple serialization delimiter

export const fields = [
    'Fremdrift ',
    'SprintStart ',
    'SprintSlutt ',
    'Påløpte timer ',
    'Gjenstående timer ',
    'Totalt antall timer ', 
] as const

type FieldsArray = typeof fields;

type FieldsAsString = {
    [K in FieldsArray[number]]: string;
};
// test

export type Doc = "old" | "new"
export type State = "" | "changed" | "deleted" | "new"

export interface Levels {
    lvl1: string,
    lvl2: string,
    lvl3: string,
}

export interface Line extends FieldsAsString, Levels {
    doc: Doc,
}

export interface Combine extends Levels {
    key: string,
    old: Line | null,
    new: Line | null,
    state: State,
    changedFields: string[]
}

const getKontrakt = (label: string): string => {
    // Given: NSD - 9224-L01 Hematologi
    // Return: 9224-L01
    const temp = label.split(" ")
    return temp[2]
}

export const normalizeLF = (doc: Doc, rawArray: any): Line[] => {
    let ret: Line[] = []
    let lvl1 = "" // 7401 MTU
    let lvl2 = "" // NSD - 9224-L01 Hematologi

    rawArray.forEach((raw: any) => {

        if (raw[LABEL].substr(0, 2) === '74') {
            lvl1 = raw[LABEL]
        }
        else if (raw[LABEL].substr(0, 3) === 'NSD') {
            lvl2 = raw[LABEL]
        }
        else if (raw[LABEL].substr(0, 2) === 'LF') {

           //  console.log('r', raw["SprintStart "])
            let line: Line = {
                doc: doc,
                lvl1: lvl1,
                lvl2: lvl2,
                lvl3: raw[LABEL],
                ...fields.reduce((acc, field) => {
                    acc[field] = raw[field] || "";
                    return acc;
                }, {} as FieldsAsString)
            }

            ret.push(line)

        }

    })


    return ret
}


export const doDiff = (oldDoc: Line[], newDoc: Line[]): Combine[] => {

    const oldIndex: { [key: string]: Line} = {}
    const newIndex: { [key: string]: Line} = {}
    const lineTuples = new Set<string>() // serialized tuples of lvl2 and lvl3
    const combines: Combine[] = []

    oldDoc.forEach(line => {
        const tuple = `${line.lvl2}${DELIMITER}${line.lvl3}`
        oldIndex[tuple] = line
        lineTuples.add(tuple)
    })

    newDoc.forEach(line => {
        const tuple = `${line.lvl2}${DELIMITER}${line.lvl3}`
        newIndex[tuple] = line
        lineTuples.add(tuple)
    })

    lineTuples.forEach(tuple => {
        const temp = tuple.split(DELIMITER)
        const lvl2 = temp[0]
        const lvl3 = temp[1]
        const lvl1 = (oldIndex[tuple] || newIndex[tuple]).lvl1
        let combine: Combine = {
            key: tuple,
            old: oldIndex[tuple] || null,
            new: newIndex[tuple] || null,
            lvl1: lvl1,
            lvl2: lvl2,
            lvl3: lvl3,
            state: "",
            changedFields: [],
        }

        if (combine.old === null) {
            combine.state = "new"
        }
        else if (combine.new === null) {
            combine.state = "deleted"
        }
        else {
            fields.forEach(field => {
                if (`${combine.old![field]}` !== `${combine.new![field]}`) {
                    combine.state = "changed"
                    combine.changedFields.push(field)
                }
            })
        }

        combines.push(combine)
    })

    return Array.from(combines).sort((a, b) => (a.key < b.key ? -1 : 1))

}