import { ListItem, Stack, Table, TableContainer, TableRowProps, Tbody, Td, Text, Th, Thead, Tr, UnorderedList, chakra, useStyleConfig } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { read, utils } from 'xlsx';
import { Combine, Doc, Line, doDiff, fields, normalizeLF } from "./DiffLF";

export const LABEL = 'Label'


const sizeStyles = {
    fontSize: "0.6rem", // Scaled font size
    padding: "0.24rem 0.48rem", // Scaled padding
    maxWidth: "6rem",
    overflow: "hidden",
};

export default function Uploader() {



    const [oldDoc, setOldDoc] = useState<Line[]>([])
    const [oldName, setOldName] = useState("")
    const [newDoc, setNewDoc] = useState<Line[]>([])
    const [newName, setNewName] = useState("")
    const [combines, setCombines] = useState<Combine[]>([])

    useEffect(() => {
        if (oldDoc.length > 0 && newDoc.length > 0 && combines.length === 0) {
            const combs = doDiff(oldDoc, newDoc)
            setCombines(combs)
        }
    }, [oldDoc, newDoc, combines])

    const handleDropFile = (acceptedFiles: any, doc: Doc) => {
        if (acceptedFiles.length) {
            acceptedFiles.forEach((file: any) => handleParseFile(file, doc))
        }
    }

    const handleParseFile = (file: any, doc: Doc) => {

        var reader = new FileReader();

        reader.onload = function (e: any) {
            var data = e.target.result;
            let dataRead = read(data, { type: 'binary', cellStyles: true, cellDates: true });
            const sheet = dataRead.Sheets['LF rapport']
            let headers = utils.sheet_to_json(sheet, { range: 2, header: 1 })[0] as string[]
            headers[0] = LABEL
            /* Convert array to json*/

            console.log('shee', sheet)
            const lf = utils.sheet_to_json(sheet, { range: 2, header: headers })
            

            console.log('x', lf)
            const lf1 = normalizeLF(doc, lf)

            if (doc === "new") {
                setNewDoc(lf1)
                setNewName(file.name)
            }
            else if (doc === "old") {
                setOldDoc(lf1)
                setOldName(file.name)
            }

        };

        reader.readAsBinaryString(file)

    }


    const oldDiv = useDropzone({ onDrop: (file: any) => handleDropFile(file, "old"), noClick: false });
    const newDiv = useDropzone({ onDrop: (file: any) => handleDropFile(file, "new"), noClick: true });


    return (
        <Stack>

            <Stack
                direction="row"
                p="16"
                alignItems="center"
                justifyContent={"center"}
                spacing="16"
            >

                <Stack
                    border="2px dashed"
                    borderColor={oldDoc.length > 1 ? 'green.300' : 'blue.300'}
                    width="400px"
                    bg={oldDoc.length > 1 ? 'green.50' : oldDiv.isDragActive ? 'blue.50' : ''}
                    height="200px"
                    borderRadius={"1.4rem"}
                    {...oldDiv.getRootProps({ className: 'dropzone' })}
                    alignItems="center"
                    justifyContent="center"
                    p="2rem"
                >
                    <input {...oldDiv.getInputProps()} />
                    <Text fontSize="3xl">{oldDoc.length > 0 ? "✅" : "📥"}</Text>
                    <Text>{oldDoc.length > 0 ? oldName : "Slipp gammel Løsningsfabrikk-plan (.xlsx) her"}</Text>
                </Stack>

                <Stack
                    border="2px dashed"
                    borderColor={newDoc.length > 1 ? 'green.300' : 'blue.300'}
                    width="400px"
                    bg={newDoc.length > 1 ? 'green.50' : newDiv.isDragActive ? 'blue.50' : ''}
                    height="200px"
                    borderRadius={"1.4rem"}
                    {...newDiv.getRootProps({ className: 'dropzone' })}
                    alignItems="center"
                    justifyContent="center"
                    p="2rem"
                >
                    <input {...newDiv.getInputProps()} />
                    <Text fontSize="3xl">{newDoc.length > 0 ? "✅" : "📥"}</Text>
                    <Text>{newDoc.length > 0 ? newName : "Slipp ny Løsningsfabrikk-plan (.xlsx) her"}</Text>
                </Stack>

            </Stack>

          

            {combines.length > 0 ? (

                <TableContainer

                >
                    <Table size='sm'
                    // transform={"scale(0.66)"}
                    // transformOrigin={"top left"}
                    >
                        <Thead>
                            <Tr>
                                <Th {...sizeStyles} colSpan={3}></Th>
                                <Th {...sizeStyles} colSpan={fields.length}>{oldName}</Th>
                                <Th {...sizeStyles} colSpan={fields.length}>{newName}</Th>
                            </Tr>
                        </Thead>
                        <Thead>
                            <Tr
                                bgColor={"gray.50"}
                            >
                                <Th {...sizeStyles}>Lvl1</Th>
                                <Th {...sizeStyles}>Lvl2</Th>
                                <Th {...sizeStyles}>Lvl3</Th>
                                {fields.map((field, i) => {
                                    return (
                                        <Th
                                            {...sizeStyles}
                                            borderLeft={i === 0 ? "solid 2px" : undefined}
                                            borderLeftColor="gray.200"
                                            key={`old${field}`}
                                        >{`${field}`}</Th>
                                    )
                                })}
                                {fields.map((field, i) => {
                                    return (
                                        <Th
                                            {...sizeStyles}
                                            borderLeft={i === 0 ? "solid 2px" : undefined}
                                            borderLeftColor="gray.200"
                                            key={`new${field}`}
                                        >{`${field}`}</Th>
                                    )
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {combines.map(combine => {

                                return (
                                    <HoverableRow
                                        key={combine.key}
                                    >
                                        <Td
                                            {...sizeStyles}
                                        >{combine.lvl1}</Td>
                                        <Td
                                            {...sizeStyles}
                                            maxW={"25rem"}
                                        >{combine.lvl2}</Td>
                                        <Td
                                            {...sizeStyles}
                                        >{combine.lvl3}</Td>
                                        {/* <Td
                                            {...sizeStyles}
                                            maxW={"25rem"}
                                        >{combine.lvl4}</Td> */}

                                        {fields.map((field, i) => {
                                            const val = (combine.old || {})[field] || ""
                                            return (
                                                <Td
                                                    {...sizeStyles}

                                                    borderLeft={i === 0 ? "solid 2px" : undefined}
                                                    borderLeftColor="gray.200"
                                                    key={`old${field}`}
                                                >{`${val}`}</Td>
                                            )
                                        })}

                                        {fields.map((field, i) => {
                                            const val = (combine.new || {})[field] || ""
                                            const cellState = combine.changedFields.includes(field)
                                            const bg = combine.state === "changed" && cellState === true ? "yellow.200"
                                                : combine.state === "deleted" ? "red.200"
                                                    : combine.state === "new" ? "green.200"
                                                        : undefined
                                            return (
                                                <Td
                                                    {...sizeStyles}

                                                    borderLeft={i === 0 ? "solid 2px" : undefined}
                                                    borderLeftColor="gray.200"
                                                    key={`old${field}`}
                                                    bg={bg}
                                                >{`${val}`}</Td>
                                            )
                                        })}
                                    </HoverableRow>
                                )
                            })}

                        </Tbody>
                    </Table>
                </TableContainer>
            ) : (
                <Stack
alignItems={"center"}
>
  <Text fontWeight={600}>
    Obs!
    </Text>
                <UnorderedList>
                    <ListItem>Pass på at regnearkene som brukes her er lagret med riktig nivå. Altså: <i>expand</i> til nivå "Kategori".</ListItem>
                    <ListItem>Det fjerde nivået, "Backlog Item", må være <i>collapsed</i>.</ListItem>
                </UnorderedList>
</Stack>
            )}

        </Stack>

    )


}




interface HoverableRowProps extends TableRowProps {
    children: React.ReactNode;
}

const HoverableRow: React.FC<HoverableRowProps> = ({ children, ...rest }) => {
    const styles = useStyleConfig("TableRow");

    return (
        // @ts-ignore
        <chakra.tr
            sx={{
                '&:hover': {
                    bg: 'gray.200',
                }
            }}
            {...rest}
            {...styles}
        >
            {children}
        </chakra.tr>
    );
};
